import React, { Component } from 'react'
import Layout from '../../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../../helper/scrollManager'
import posed from 'react-pose'
import { easeFunction } from '../../helper/variables'
import { connect } from 'react-redux'
import Footer from '../../components/common/footer'

import './workDetail.css'
import './dlight.css'
import HeadBlock from '../../components/workDetailBlocks/headBlock'
import DetailImage from '../../components/workDetailBlocks/detailImage'
import VisibilityWrapper from '../../helper/visibilityWrapper'
import { graphql } from 'gatsby'
import _ from 'lodash'
import WorksBlock from '../../components/workDetailBlocks/worksBlock'
import CTA from '../../components/common/cta'
import { isBrowser, BrowserView } from 'react-device-detect'
import SectionIndicator from '../../components/common/sectionIndicator'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Gotenna extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    triggerPointCTA: 0,
    triggerPointFooter: 0,
    randomPosts: null,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionCTA = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.pickRandom()

    this.assignTriggerPoints()

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.assignTriggerPoints)

    this.props.hideSymbol()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  assignTriggerPoints = event => {
    const { momentumScrollValue } = this.state

    this.setState({
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
      triggerPointCTA:
        momentumScrollValue +
        this.sectionCTA.current.getBoundingClientRect().top,
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.assignTriggerPoints)
  }

  pickRandom = () => {
    const selfRemoved = _.differenceWith(
      this.props.data.next.edges,
      [{ node: this.props.data.contentfulWork }],
      function(article1, article2) {
        return article1.node.id === article2.node.id
      }
    )

    this.setState(
      {
        randomPosts: _.take(_.shuffle(selfRemoved), 2),
      },
      () => {
        setTimeout(this.assignTriggerPoints, 500)
      }
    )
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointCTA - (isBrowser ? 75 : 55)) {
      this.props.backgroundRed()
    } else {
      this.props.backgroundWhite()
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulWork
    const { theme } = this.props
    const { momentumScrollValue, randomPosts } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta
            property="og:url"
            content="https://www.baunfire.com/work/gotenna"
          />
          <link rel="canonical" href="https://www.baunfire.com/work/gotenna" />
        </Helmet>
        <PageTransition
          style={{
            backgroundColor: '#f9f9f9',
          }}
        >
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <div className="page-work-detail dlight">
              <section className="detail-head">
                <HeadBlock page={page} />
              </section>
              <section className="detail-content">
                <div className="image-container-0">
                  <DetailImage data={page.images[0].file} />
                </div>
                <div className="image-container-1 content-container">
                  <DetailImage data={page.images[1].file} />
                </div>

                <div className="text-block content-container">
                  <VisibilityWrapper partialVisibility={true}>
                    {({ isVisible }) => {
                      return (
                        <div className="text-block-container">
                          <h3
                            className={`type-h6 text-block-title animation animation-${isVisible &&
                              'appear'}`}
                          >
                            Improved path to purchase
                          </h3>
                          <p
                            className={`animation animation-${isVisible &&
                              'appear'}`}
                          >
                            The new goTenna.com features an all-new user
                            interface paired with high-fidelity product and
                            lifestyle imagery. Our vision was to optimize the
                            user journey by organizing content on each page and
                            surfacing products in context. As important as it
                            was to create high-end visuals, the ultimate goal
                            was to drive traffic and conversion on their eComm
                            platform.
                          </p>
                        </div>
                      )
                    }}
                  </VisibilityWrapper>
                </div>
                <div className="image-container-2 regular-padding content-container">
                  <DetailImage data={page.images[2].file} />
                </div>
                <div className="image-container-2 bottom-padding content-container">
                  <DetailImage data={page.images[3].file} />
                </div>
                <div className="flex-block content-container bottom-padding">
                  <div className="flex-column left">
                    <VisibilityWrapper partialVisibility={true}>
                      {({ isVisible }) => {
                        return (
                          <div className="text-block">
                            <h3
                              className={`type-h6 text-block-title animation animation-${isVisible &&
                                'appear'}`}
                            >
                              Company growth in mind
                            </h3>
                            <p
                              className={`animation animation-${isVisible &&
                                'appear'}`}
                            >
                              With company growth as a priority for goTenna, it
                              was essential to effectively showcase office
                              culture and career opportunities with the goal of
                              attracting qualified talent.
                            </p>
                          </div>
                        )
                      }}
                    </VisibilityWrapper>
                    <DetailImage data={page.images[4].file} />
                  </div>
                  <div className="flex-column right">
                    <DetailImage data={page.images[5].file} />
                  </div>
                </div>

                <div className="image-container-5">
                  <DetailImage data={page.images[6].file} />
                </div>

                <div className="mobile-container bottom-padding ">
                  <div className="content-container">
                    <div className="left column">
                      <VisibilityWrapper partialVisibility={true}>
                        {({ isVisible }) => {
                          return (
                            <div className="mobile-text">
                              <h3
                                className={`type-h6 text-block-title animation animation-${isVisible &&
                                  'appear'}`}
                              >
                                All-New Mobile Platform
                              </h3>
                              <p
                                className={`animation animation-${isVisible &&
                                  'appear'}`}
                              >
                                The redesigned mobile experience delivers clear
                                product storytelling in a way that effectively
                                educates and engages potential customers.
                              </p>
                            </div>
                          )
                        }}
                      </VisibilityWrapper>
                      <div className="image-to-right">
                        <DetailImage data={page.mobileImages[0].file} />
                      </div>
                    </div>
                    <div className="right column">
                      <div className="image-to-left">
                        <DetailImage data={page.mobileImages[1].file} />
                      </div>
                      <div className="image-to-left">
                        <DetailImage data={page.mobileImages[2].file} />
                      </div>
                    </div>
                  </div>
                </div>
                <section className="next-case">
                  {randomPosts && (
                    <WorksBlock
                      posts={randomPosts}
                      momentumScrollValue={momentumScrollValue}
                    />
                  )}
                </section>
                <VisibilityWrapper partialVisibility={true}>
                  {({ isVisible }) => {
                    return (
                      <div
                        className={`area-cta ${isVisible && 'appear'}`}
                        ref={this.sectionCTA}
                      >
                        <div className="content-container">
                          <section className="section-cta">
                            <CTA momentumScrollValue={momentumScrollValue} />
                          </section>
                        </div>
                      </div>
                    )
                  }}
                </VisibilityWrapper>
              </section>
              <section ref={this.sectionFooter}>
                <Footer />
              </section>
            </div>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator theme={theme}>Case Studies</SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String = "gotenna") {
    contentfulWork(slug: { eq: $slug }) {
      id
      metaData {
        title
        metaTitle
        description
        keywords
        ogImage {
          file {
            url
          }
        }
      }
      slug
      project
      service
      role
      headline
      description {
        description
      }
      keyImage {
        file {
          url
        }
      }
      images {
        id
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      mobileImage {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      mobileImages {
        id
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
    next: allContentfulWork(filter: {
      live: {
        eq: true
      }
    }) {
      edges {
        node {
          id
          project
          slug
          type
          thumbnail {
            file {
              url
              fileName
              contentType
            }
            description
            fluid(maxWidth: 840, quality: 90) {
              src
              srcSet
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedWorkDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(Gotenna)

export default ConnectedWorkDetail
